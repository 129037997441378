import { ColdDollarSignIcon } from '../components/atoms/icons/coldDollarSignIcon';

export enum IconNames {
  ColdHomeIcon = 'ColdHomeIcon',
  ColdFootprintIcon = 'ColdFootprintIcon',
  ColdFootprintIconTwo = 'ColdFootprintIconTwo',
  ColdFootprintIconThree = 'ColdFootprintIconThree',
  ColdJourneyIcon = 'ColdJourneyIcon',
  ColdActionsIcon = 'ColdActionsIcon',
  ColdReportIcon = 'ColdReportIcon',
  ColdSettingsIcon = 'ColdSettingsIcon',
  CloseModalIcon = 'CloseModalIcon',
  ColdBillingIcon = 'ColdBillingIcon',
  ColdTeamMembersIcon = 'ColdTeamMembersIcon',
  ColdSmallCheckBoxIcon = 'ColdSmallCheckBoxIcon',
  ColdScoreIcon = 'ColdScoreIcon',
  ColdActionsCompletedIcon = 'ColdActionsCompletedIcon',
  ColdRightArrowIcon = 'ColdRightArrowIcon',
  ColdEmptyCheckboxIcon = 'ColdEmptyCheckboxIcon',
  ColdChevronUpIcon = 'ColdChevronUpIcon',
  ColdChevronDownIcon = 'ColdChevronDownIcon',
  ColdCheckIcon = 'ColdCheckIcon',
  ColdComplianceIcon = 'ColdComplianceIcon',
  ColdDocumentsIcon = 'ColdDocumentsIcon',
  PlusIcon = 'PlusIcon',
  SubtractIcon = 'SubtractIcon',
  ColdComplianceSurveyCheckBoxIcon = 'ColdComplianceSurveyCheckBoxIcon',
  ColdAiIcon = 'ColdAiIcon',
  ColdBookmarkIcon = 'ColdBookmarkIcon',
  ColdDocumentUploadIcon = 'ColdDocumentUploadIcon',
  ColdSwitchIcon = 'ColdSwitchIcon',
  ColdCalendarCloseIcon = 'ColdCalendarCloseIcon',
  ColdCalendarDaysIcon = 'ColdCalendarDaysIcon',
  ColdCalendarEventIcon = 'ColdCalendarEventIcon',
  ColdClockIcon = 'ColdClockIcon',
  ColdInfoIcon = 'ColdInfoIcon',
  ColdLeftArrowIcon = 'ColdLeftArrowIcon',
  ColdSupportNotFilledInIcon = 'ColdSupportNotFilledInIcon',
  ColdAddNotesIcon = 'ColdAddNotesIcon',
  ColdSparkleIcon = 'ColdSparkleIcon',
  ColdSuppliersNavIcon = 'ColdSuppliersNavIcon',
  ColdExpiringIcon = 'ColdExpiringIcon',
  ColdDangerIcon = 'ColdDangerIcon',
  ColdMaterialsNavIcon = 'ColdMaterialsNavIcon',
  ColdProductsNavIcon = 'ColdProductsNavIcon',
  ColdQuestionnaireIcon = 'ColdQuestionnaireIcon',
  ColdChartIcon = 'ColdChartIcon',
  ColdSustainabilityIcon = 'ColdSustainabilityIcon',
  ColdUnknownIcon = 'ColdUnknownIcon',
  ColdEditPencilIcon = 'ColdEditPencilIcon',
  ColdDollarSignIcon = 'ColdDollarSignIcon',
}
