import { IconProps } from '@coldpbc/interfaces';


export const ColdDollarSignIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M16 9.00021C16 7 15 6 12 6M12 6C9 6 8 7 8 9C8 11 9 12 12 12C15 12 16 13 16 15C16 17 14 18 12 18M12 6V4M12 18C10 18 8 17 8 15M12 18V20"
        stroke={props.color || "white"} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
};
